<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
      </div>
      <div class="card-toolbar">
        <!--begin::Filter Menu-->
        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="/media/icons/duotune/general/gen024.svg" />
          </span>
        </button>
        <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
          <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{generalConstants.FILTER_COLUMNS}}</div>
          </div>
          <div class="separator border-gray-200"></div>
          <div class="px-7 py-5">
            <div class="mb-10">
              <span v-for="header in tableHeader" :key="header.key">
              <el-checkbox
                :disabled="visibleFields.length == 1 && header.visible"
                v-model="header.visible"
                v-if="header.key != 'actions'"
                class="me-2"
                inline >
                {{ header.name }}
              </el-checkbox>
              </span>
            </div>
            <div class="d-flex justify-content-end">
              <!-- <button type="reset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true" > Reset </button> -->
              <button type="submit" @click="submitFilters(visibleFields)" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" > {{generalConstants.SAVE}} </button>
              </div>
          </div>
        </div>
        <!--end::Filter Menu-->
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-xl-12">
          <!--begin::Accordion-->
          <div class="accordion" id="kt_accordion_1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="kt_accordion_1_header_1">
                <button class="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="false" aria-controls="kt_accordion_1_body_1">
                  <h4 class="fw-bolder m-0">Filters</h4>
                </button>
              </h2>
              <div id="kt_accordion_1_body_1" class="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                <div class="accordion-body">
                  <div class="d-flex align-items-center position-relative my-1">
                    <div class="row">
                      <div class="col-md-2">
                        <input type="text" v-model="searchParams.search" v-on:keyup.enter="handleSearch()" class="form-control me-2" :placeholder="ordersConstants.SEARCH_ORDERS" />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.orderType" :placeholder="ordersConstants.ORDER_TYPE" size="large">
                          <el-option value="">{{ordersConstants.ORDER_TYPE}}</el-option>
                          <el-option value="0" label="Manual">Manual</el-option>
                          <el-option value="1" label="Online">Online</el-option>
                          <el-option value="2" label="Offline">Offline</el-option>
                        </el-select>
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.partner" :placeholder="ordersConstants.TRADING_PARTNER" size="large">
                          <el-option value="">{{ordersConstants.TRADING_PARTNER}}</el-option>
                          <el-option v-for="partner in tradingPartners" :value="partner.trading_partner_id" :key="partner" :label="partner.user.name">
                            {{partner.user.name}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order date"
                          end-placeholder="Order date"
                          size="large"
                        />
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDueDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order Due date"
                          end-placeholder="Order Due date"
                          size="large"
                          :shortcuts="shortcuts"
                          unlink-panels
                        />
                      </div>
                      <div class="col-md-2 mt-2">
                        <el-select v-model="searchParams.searchByOrderStatus" :placeholder="ordersConstants.ORDER_STATUS" size="large">
                          <el-option value="">{{ordersConstants.ORDER_STATUS}}</el-option>
                          <el-option v-for="status in orderStatus" :value="status.type" :key="status" :label="status.title">
                            {{status.title}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-2 mt-2">
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
                          <button type="button" @click.prevent="handleSearch()" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
                        </el-tooltip>
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
                          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Accordion-->
        </div>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-id="{ row: data }">
          <p class="mt-2 mb-0" v-if="permission.isViewAllowed"><a href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary" @click.prevent="openDetailPage(data.id)"> #{{data.id}}</a></p>
          <p class="mt-2 mb-0" v-else>#{{data.id}}</p>
        </template>
         <template v-slot:cell-invoice_number="{ row: data }">
          <span v-if="data.invoice_number && data.invoice_number != ''">
              {{ data.invoice_number}}
           </span>
           <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-order_type="{ row: data }">
           <span v-if="data.order_type && data.order_type == '0'">
              Manual
           </span>
           <span v-else-if="data.order_type && data.order_type == '1'">
              Online
           </span>
           <span v-else-if="data.order_type && data.order_type == '2'">
              Offline
           </span>
           <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-partner="{ row: data }">
          <div v-if="permission.isViewAllowed"><a href="javascript:void(0)" @click.prevent="openDetailPage(data.id)">
            <el-tooltip class="box-item" effect="dark" :content="((data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name + '<br>' : '') + ((data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) ? '(' + data.trading_partner.user.name + ')' : '')" placement="top" raw-content>
              <a href="javascript:void(0)" @click.prevent="openDetailPage(data.id)">
                  <img :src="setImage(data.trading_partner.partner_image_path)" class="w-25px ms-n1 rounded-circle" :alt="(data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name : '' ">
              </a>
            </el-tooltip>
          </a>
          </div>
          <div v-else>
            <el-tooltip class="box-item" effect="dark" :content="((data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name + '<br>' : '') + ((data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) ? '(' + data.trading_partner.user.name + ')' : '')" placement="top" raw-content>
              <img :src="setImage(data.trading_partner.partner_image_path)" class="w-25px ms-n1 rounded-circle" :alt="(data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name : '' ">
            </el-tooltip>
          </div>
        </template>
        <template v-slot:cell-order_id="{ row: data }">
          <p class="mt-2 mb-0" v-if="permission.isViewAllowed"><a href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary" @click.prevent="openDetailPage(data.id)"> {{ data.order_id}}</a></p>
          <p class="mt-2 mb-0" v-else>#{{ data.order_id}}</p>
        </template>
        <template v-slot:cell-customer_details="{ row: data }">
          <span v-if="data.customer_name || data.customer_phone || data.customer_email">
            <dl class="row mb-0" v-if="data.customer_name || data.customer_phone">
                <span v-if="data.customer_name">{{data.customer_name}} <span v-if="data.customer_phone"> <a :href="`tel:${data.customer_phone}`" class="text-gray-600 text-hover-primary mb-1">({{data.customer_phone}})</a></span></span>
            </dl>
            <dl class="row mb-0" v-if="data.customer_email">
                <span><a :href="`mailto:${data.customer_email}`" class="text-gray-600 text-hover-primary mb-1">{{data.customer_email}}</a></span>
            </dl>
          </span>
          <span v-else>
              -
          </span>
        </template>
        <template v-slot:cell-order_date="{ row: data }">
          <span v-if="data.order_date">
            {{ formatDateTime(data.order_date)}}
           </span>
           <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-order_due_date="{ row: data }">
          <span v-if="data.order_due_date">
            {{ formatDate(data.order_due_date)}}
          </span>
          <span v-else>
             -
          </span>
        </template>
        <template v-slot:cell-order_products_count="{ row: data }">
          <span v-if="data.order_products_count">
            <a href="javascript:void(0)" @click="openDetailPage(data.id)"><span class="badge bg-warning">{{ data.order_products_count }}</span></a>
          </span>
          <span v-else>
            -
          </span>
        </template>
        <template v-slot:cell-total_amount="{ row: data }">
          <span v-if="data.order_payment && (data.order_payment.shipping_charges || data.order_payment.subtotal || data.order_payment.tax || data.order_payment.total_amount)">
            <span v-if="data.order_payment.shipping_charges">{{ordersConstants.SHIPPING_CHARGES}} : {{ formatPrice(data.order_payment.shipping_charges) }} <br>&nbsp; </span>
            <span v-if="data.order_payment.subtotal">{{ordersConstants.SUBTOTAL}} : {{ formatPrice(data.order_payment.subtotal) }} <br>&nbsp; </span>
            <span v-if="data.order_payment.tax">{{ordersConstants.TAX}} : {{ formatPrice(data.order_payment.tax) }} <br>&nbsp; </span>
            <span v-if="data.order_payment.total_amount">{{ordersConstants.TOTAL_AMOUNT}} : {{ formatPrice(data.order_payment.total_amount) }} <br>&nbsp; </span>
          </span>
          <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-payment_shipping="{ row: data }">
          <span v-if="data.shipping_method || data.shipping_type">
            <span>{{ data.shipping_method }} </span> <span>{{ data.shipping_type }}</span>
          </span>
          <span v-else>-</span>
        </template>
         <template v-slot:cell-order_status="{ row: data }">
          <span v-if="data.orderstatus && data.orderstatus.title" :class="`badge badge-light-${data.orderstatus.badge}`" >
            {{ data.orderstatus.title }}
          </span>
          <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isViewAllowed || permission.isViewProductAllowed">
            <el-tooltip class="box-item" effect="dark" :content="ordersConstants.VIEWORDERPRODUCTS" placement="top" v-if="permission.isViewProductAllowed">
              <button type="button" @click="assignViewOrderProductModalId(data.id)"  data-bs-toggle="modal" data-bs-target="#order_products_modal" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-bag-check"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" :content="generalConstants.VIEW" placement="top" v-if="permission.isViewAllowed">
              <button type="button" @click.prevent="openDetailPage(data.id)" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-eye"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
              -
          </div>
        </template>
      </Datatable>
    </div>
    <ViewOrderProducts  ref="orderId"
    ></ViewOrderProducts>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatPrice, formatDate, formatText, formatDateTime } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";
import ViewOrderProducts from "@/components/modals/forms/ViewOrderProducts.vue";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "pending-orders",
  components: {
    Datatable,
    ViewOrderProducts
  },
  setup() {
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const tradingPartners = ref([]);
    const orderStatus = ref([]);
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const orderId = ref("");
    const store = useStore();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchData = ref(store.getters.getOrderSearch(moduleName.value));

    const searchParams = reactive({
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      page_type: "order_listing",
      partner: searchData.value.searchByTradingPartner,
      search: searchData.value.search,
      sortBy:"id",
      sortDesc:"desc",
      searchByOrderDate: searchData.value.searchByOrderDate,
      searchByOrderDueDate: searchData.value.searchByOrderDueDate,
      orderType: searchData.value.searchByOrderType,
      searchByOrderStatus: searchData.value.searchByOrderStatus,
      isPendingOrder:1
    })

    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]

    const permission = reactive({
      isViewAllowed: false,
      isViewProductAllowed: false
    })

    const tableHeader = reactive([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        visible: true,
      },
      {
        name: ordersConstants.PARTNER,
        key: 'partner',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.ID,
        key: 'id',
        visible: true,
      },
      {
        name: ordersConstants.INVOICE_NO,
        key: 'invoice_number',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_TYPE,
        key: 'order_type',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.PARTNER_ORDER_ID,
        key: 'order_id',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.CUSTOMER_DETAILS,
        key: 'customer_details',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_DATE,
        key: 'order_date',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_DUE_DATE,
        key: 'order_due_date',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.PRODUCT_ITEMS,
        key: 'order_products_count',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.PAYMENT_AMOUNT,
        key: 'total_amount',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.SHIPPING_AND_METHOD,
        key: 'payment_shipping',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_STATUS,
        key: 'order_status',
        visible: false,
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        sortable: false,
        visible: true,
        sortable: false,
      },
    ]);
    const visibleFields = computed(() => {
      return tableHeader.filter(header => header.visible)
    })

    const assignViewOrderProductModalId = (id) => {
      const isPending = true;
      orderId.value.getOrderProducts(id, isPending);
    }

    const submitFilters = async (filters) => {
      if (!tableHeader) return
        let filterData = {
          orderFilters: tableHeader.map(({key, visible}) => ({[key]: visible})),
        };
        ApiService.post('users/save-filters', filterData)
        .then((response) => {
          if (response.data) {
            let status = response.status;
            let message = response.data.message;
            if (status == 200 || status == 201) {
              notificationFire(message,'success');
            }
            else
            {
              notificationFire(message,'error');
            }
          }
        })
        .catch((error) => {
          loading.value = false;
          loadingback.value = false;
          let message = error.message;
          notificationFire(message,'error');
        });
    }
    
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    }

    const handleSortChange = (obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      getOrders();
    }

    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getOrders();
    }

    const openDetailPage = (order_id) => {
      router.push('/pending-orders/'+order_id)
    }

    //Get all orders
    const getOrders = async () => {
      loading.value = true;
      await ApiService.query("orders", { params: searchParams })
      .then(({ data }) => {
        if(data.data.filter)
        {
          var filterData = data.data.filter;
          tableHeader.forEach((options, headerKey) => {
            let key = options.key;
            if (filterData[key] !== undefined) {
              tableHeader[headerKey].visible = filterData[key];
            }
          });
        }
        if (data.data.data.length) {
          tableData.value.splice(0, tableData.value.length, ... data.data.data);
          total.value = data.data.total;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
        }
        else
        {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
        setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tableData.value = [];
        total.value = 0;
        loading.value = false;
      });
    }

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
      }
      searchParams.partner = "";
      searchParams.searchByOrderDate = "";
      searchParams.searchByOrderDueDate = "";
      searchParams.orderType = "";
      searchParams.searchByOrderStatus = "";
      rememberSearch();
      getOrders();
    };

    //Order search event function
    const handleSearch = () => {
      rememberSearch();
      handleCurrentChange(1);
    }

    //Get all active trading platforms
    const getTradingPlatforms = async () => {
      loading.value = true;
      await ApiService.query("get-active-trading-partner")
      .then(({ data }) => {
        if (data.data) {
          tradingPartners.value = data.data;
        } else {
          tradingPartners.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tradingPartners.value = [];
        loading.value = false;
      });
    }

    //Get all active statuses
    const getActiveStatusForOrders = async () => {
      loading.value = true;
      await ApiService.query("get-order-status")
      .then(({ data }) => {
        if (data.data) {
          orderStatus.value = data.data;
        } else {
          orderStatus.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        orderStatus.value = [];
        loading.value = false;
      });
    }

    const rememberSearch = () => {
      let actionParams = {
          "search": searchParams.search,
          "searchByTradingPartner": searchParams.partner,
          "searchByOrderType": searchParams.orderType,
          "searchByOrderDate": searchParams.searchByOrderDate,
          "searchByOrderDueDate": searchParams.searchByOrderDueDate,
          "searchByOrderStatus": searchParams.searchByOrderStatus
      }
      store.dispatch(Actions.ORDER_SEARCH, {'module':moduleName.value,'params':actionParams});
    };
    
    const setImage = (image) => {
        if (image == null) {
            return '/media/avatars/blank.png';
        }
      return process.env.VUE_APP_API_URL + "../../../partner_image/" + image;
    };

    //Set breadcrumbs and get the orders
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("viewPendingOrder") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("viewPendingOrderProduct") || role == "Super Admin")
        permission.isViewProductAllowed = true;
      getTradingPlatforms();
      getActiveStatusForOrders();
      await getOrders();
    })
    
    
    return {
      tableData,
      formatPrice,
      formatDate,
      formatDateTime,
      getOrders,
      total,
      handleSizeChange,
      handleCurrentChange,
      loading,
      searchParams,
      openDetailPage,
      visibleFields,
      submitFilters,
      tradingPartners,
      getTradingPlatforms,
      generalConstants,
      ordersConstants,
      tableHeader,
      noDataFound,
      updateProps,
      resetSearch,
      handleSearch,
      assignViewOrderProductModalId,
      orderId,
      handleSortChange,
      shortcuts,
      permission,
      setImage,
      getActiveStatusForOrders,
      orderStatus
    }
  },
};

</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.el-link {
  justify-content: left;
}
.el-popper.is-dark {
  text-align: center !important;
}
</style>